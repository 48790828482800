.App {
  text-align: center;
}
.tableContainer{
  padding-top: 2%;
}
.sidebar-item{
  justify-content: left;
  margin:4%;
  padding-bottom:10%;
  font-size:1.05rem;
}
.side-bar{
  width: 10% !important;
}
.span-sidebar{
  cursor: pointer;
}
.nav-brand-text{
  padding-left:1% !important;
  /* font-size: 1.45rem; */
}
#green-help{
  color:green;
}
#red-help{
  color:red;
}
#left-col{
  padding-right: 2%;
}
/* Modal */

/* Modal close button */
.close{
  font-size: 2rem;
}


#shutdown-h1{
  padding-right: 1%;
}
.bottom-bar{
  width:100%;
  background-color: black;
  float:none
}

/* Signin */
amplify-authenticator {
  display: flex;
  justify-content: center;
  background-color: white;
  align-items: center;
  flex: 1;
  --amplify-font-family: Helvetica Neue;
  height: 100vh;
}
:root{
  --amplify-primary-color: #6c757d;
  --amplify-primary-tint: #424242;
  --amplify-secondary-color: #424242;
  --amplify-primary-shade: #424242;
}



.all-station-toggle{
  height: 24px;
  padding-top: 12px;
  padding-left:0.7%;
}

html,body,.container{
  height: 90%;
}
.TableItem:nth-child(odd){
  background-color: #f2f2f2;
}
.TableItem{
  height: 50px;
  justify-content: flex-start;
}

/* Smaller Laptops/ Big Tablets */
@media only screen and (max-width: 1638px) and (min-width: 1051px){
  .station-name{
    font-size: 1.2rem !important;
  }

} 
/* Tablets and Some phones */
@media only screen and (max-width: 1050px) and (min-width: 601px){
  .station-name{
    font-size: 0.8rem !important;
  }
  .ap {
    height: 10px !important;
    width: 10px !important;
    margin: 0.5% !important;
  }
}

/* Phones */
@media only screen and (max-width: 600px){
  .station-name{
    font-size: 0.8rem !important;
  }
  .TableItem{
    height:70px !important
  }
  .row{
    margin-right: -10px;
    margin-left: -10px;
  }
  .ap {
    height: 6px !important;
    width: 6px !important;
    margin: 0.3% !important;
  }
  .react-toggle-thumb {
    top: 0px !important;
    left: 1px !important;
    width: 18px !important;
    height: 18px !important;
  }
  .react-toggle--checked .react-toggle-thumb {
    left: 22px !important;
}
.react-toggle-track {
  width: 40px !important;
  height: 19px !important;
  padding: 0 !important;
  border-radius: 25px !important;
  }
}
/* Title Break Point */
@media screen and (max-width:1081px) and (min-width: 898px) {
  .navbar h1{
    font-size: 2rem !important;
  }
}
/* Title hide on smaller devices */
@media screen and (max-width:897px){
  .navbar h1{
    visibility: hidden !important;
  }
}
.table-row{
  /* width:750px !important; */
  /* border: solid;
  border-radius: 1px; */
}
.bai-footer{
  position: absolute;
  bottom: 20px;
  font-size: 13px;
  text-align: center;
}
body {
  font-family: Helvetica Neue;
  color: black
}



.dTable{
  /* padding-left: 10%; */
  /*height: 70vh !important;*/
}
.content{
  display:table;
  padding-left: 0px;
  margin-left: 0%;
  min-height:100vh;
}
.root-Row{
  height: 100%;
  display: table-row;
}
.root-Row .side-bar{
  table-layout: fixed;
  display: table-cell;
  float: none;
}
.side-bar{
  width: 100%;
  /* height: 100%; */
  background-color: black;
  color:white;
}
.dTableItems{
  height: 75vh !important;
  overflow-y: scroll !important;
}
.status-cell{
  padding-top: 10px;
}
.Headers{
  padding-bottom: 2%;
}
.station-name{
  padding: 2%;
  font-size: 1.5rem;
}
.navbar-default {
  color:white;
  background-image: black;
  /* background-image: linear-gradient(to bottom,#2c4053 0,#2c4053 100%) !important; override background image gradient w/ flat color */
}

.aps {
  padding: 10px;
  display: inline-block;
}

.ap {
  height: 15px;
  width: 15px;
  margin: 0.5%;;
  border-radius: 50%;
  display: inline-block;
}

.unreachable {
  background-color: #bbb;
}
.help-span{
  height: 10px !important;
  width: 10px !important;
}
.broadcasting {
  background-color: green;
}

.silent {
  background-color: red;
}

.pending {
  background-color: orange;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.bg-dark{
  background-color: black !important;
}
.App-header {
  background-color: black;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
